export const REPAIR_GUARANTEE_STATUS = [
  { label: "Đã tiếp nhận", value: 1, color: "grey darken-2" },
  { label: "Đã gửi đi", value: 2, color: "blue darken-2" },
  { label: "Đã về, chờ kiểm tra", value: 3, color: "green darken-2" },
  { label: "Đã kiểm tra xong", value: 4, color: "yellow darken-2" }
];

export const REPAIR_STATUS = [
  { label: "Chờ phương án", value: 1, color: "grey darken-1" },
  { label: "Chờ xử lý", value: 2, color: "yellow darken-2" },
  { label: "Đang xử lý", value: 3, color: "blue darken-2" },
  { label: "Đã xử lý xong", value: 4, color: "green darken-2" }
];

export const ACTION_STATUS = [
  { label: "Hủy", value: 0, color: "red darken-2" },
  { label: "Lưu", value: 1, color: "yellow darken-2" },
  { label: "Duyệt bán", value: 2, color: "green darken-2" },
  { label: "Chuyển xử lý", value: 3, color: "blue darken-2" }
];

export const findedStatus = (statusList, statusValue) => {
  return statusList.find(item => item.value === statusValue);
};

export const INTERNAL_GUARANTEE_HEADER = [
  {
    text: "Mã phiếu",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Sản phẩm",
    align: "start",
    sortable: false,
    value: "serial.product_name"
  },
  {
    text: "Serial",
    align: "start",
    sortable: false,
    value: "serial.serial_number"
  },
  {
    text: "Người tiếp nhận",
    align: "start",
    sortable: false,
    value: "assigned_to_user.name"
  },
  {
    text: "Tình trạng máy",
    align: "start",
    sortable: false,
    value: "tinh_trang_may"
  },
  {
    text: "Thời gian xử lý",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Hạn xử lý",
    align: "start",
    sortable: true,
    value: "due_date"
  },
  {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "step"
  },
  {
    text: "Hành động",
    align: "start",
    sortable: false,
    value: "selected_action"
  }
];

export const INTERNAL_REPAIR_HEADER = [
  {
    text: "Mã phiếu",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Sản phẩm",
    align: "start",
    sortable: false,
    value: "serial.product_name"
  },
  {
    text: "Serial",
    align: "start",
    sortable: false,
    value: "serial.serial_number"
  },
  {
    text: "Người tiếp nhận",
    align: "start",
    sortable: false,
    value: "assigned_to_user.name"
  },
  {
    text: "Tình trạng máy",
    align: "start",
    sortable: false,
    value: "tinh_trang_may"
  },
  {
    text: "Đối tác sửa chữa",
    align: "start",
    sortable: false,
    value: "service_supplier"
  },
  {
    text: "Tổng chi phí",
    align: "start",
    sortable: false,
    value: "total_fee"
  },
  {
    text: "Thời gian xử lý",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Hạn xử lý",
    align: "start",
    sortable: true,
    value: "due_date"
  },
  {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "step"
  },
  {
    text: "Hành động",
    align: "start",
    sortable: false,
    value: "selected_action"
  }
];

export const MEDIA_LOAN_STATUS = [
  { label: "Nhận yêu cầu", value: 1 },
  { label: "Đang cho mượn", value: 2 },
  { label: "Đã nhận về", value: 3 },
  { label: "Đã kiểm tra", value: 4 }
];
